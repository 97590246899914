import "./ToggleSwitch.css"

const ToggleSwitch = ({ isDelivered, handleToggle, colorOne, colorTwo }) => {
  return (
    <>
      <input
        checked={isDelivered}
        onChange={handleToggle}
        className="switch-checkbox"
        id={`switch`}
        type="checkbox"
      />
      <label
        style={{ background: isDelivered ? colorTwo : colorOne }}
        className="switch-label"
        htmlFor={`switch`}
      >
        <span className={`switch-button`} />
      </label>
    </>
  )
}

export default ToggleSwitch
