import "./LoadingAnimation.css"

const LoadingAnimation = () => {
  return (
    <div
      className="invisibleWrapper"
      style={{ backgroundColor: "#00000020", flexDirection: "column" }}
    >
      <div className="jumper"></div>
      <p>LOADING...</p>
    </div>
  )
}

export default LoadingAnimation
