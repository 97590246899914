import { useNavigate } from 'react-router-dom';
import './Home.css'

const Home = () => {
    const navigate = useNavigate()
    return ( 
        <div className="homeWrapper">
            <div className="homeContainer">
                <h1>ecoloco</h1>
                <p onClick={()=> navigate('/login')}>Admin</p>
                <p onClick={()=> navigate('/login')}>Super admin</p>
            </div>
        </div>
    );
}
 
export default Home;