import "./TemporarilyClosed.css"
import closed from "../../assets/images/closed.png"

const TemporarilyClosed = ({ logout }) => {
  return (
    <div className="invisibleWrapper" style={{ flexDirection: "column" }}>
      <img className="img-closed" src={closed} alt="store is closed" />
      <div className="logoutContainer">
        <p className="btn-logout" onClick={() => logout()}>
          Logout
        </p>
      </div>
    </div>
  )
}

export default TemporarilyClosed
