import { useContext, useMemo } from "react"
import "./CsvExportProducts.css"
import { AuthContext } from "../../Context/AuthContext"

const CsvExportProducts = () => {
  const { products } = useContext(AuthContext)

  const csvList = useMemo(() => {
    if (products.length > 0) {
      let csvContent = "Name,Type,Price,Size\n"

      products.forEach((el) => {
        csvContent += `${el.name},${el.type},${el.price},${el.size.map(
          (item) => item.value
        )}\n`
      })
      return csvContent
    }
  }, [products])

  const exportCsvProducts = () => {
    if (csvList) {
      const blob = new Blob([csvList], { type: "text/csv" })
      const url = URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.setAttribute("href", url)
      link.setAttribute("download", "products.csv")

      link.click()
      URL.revokeObjectURL(url)
    }
  }
  return (
    <p className="filterTag" onClick={exportCsvProducts}>
      Export
    </p>
  )
}

export default CsvExportProducts
