import { createContext, useEffect, useState } from "react"
import { auth, db } from "../firebase/config"
import { onAuthStateChanged } from "firebase/auth"
import {
  child,
  equalTo,
  get,
  onValue,
  orderByChild,
  query,
  ref,
} from "firebase/database"
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
  setDataToLocalStorage,
} from "../helpers/auth"

export const AuthContext = createContext()

const AuthContextComponent = ({ children }) => {
  const [user, setUser] = useState(getDataFromLocalStorage("role"))
  const [makingStore, setMakingStore] = useState(false)
  const [userId, setUserId] = useState(getDataFromLocalStorage("adminId"))
  const [store, setStore] = useState({ stripeId: "something", enabled: true })
  const [products, setProducts] = useState([])
  const [orders, setOrders] = useState([])

  useEffect(() => {
    //checking real session of the user after every refresh, this is slower async call and beacuse of that exist above useEffect
    const chechAuth = () => {
      onAuthStateChanged(auth, async (currUser) => {
        if (!currUser) {
          setUser(null)
          removeDataFromLocalStorage("role")
        } else {
          setUserId(currUser.uid)
          if (user) return
          try {
            const dbRef = ref(db)
            const result = await get(child(dbRef, `admins/${currUser.uid}`))
            if (result.exists()) {
              setUser(result.val().role)
              setDataToLocalStorage("role", result.val().role)
            } else {
              setUser(null)
            }
          } catch (error) {
            console.log(error)
          }
        }
      })
    }
    chechAuth()
  }, [user])

  useEffect(() => {
    //this useEffect will only play its role when superadmin makes new admin and store
    if (user === null && makingStore) {
      setUser("superadmin")
    }
  }, [makingStore, user])

  useEffect(() => {
    //if user is store admin we will fetch his store
    if (user === "admin" && userId) {
      const fetchStore = () => {
        const storeRef = ref(db, "stores")
        const storeQuery = query(
          storeRef,
          orderByChild("adminId"),
          equalTo(userId)
        )

        onValue(storeQuery, (snapshot) => {
          if (snapshot.exists()) {
            const storeId = Object.keys(snapshot.val())[0]
            setStore(snapshot.val()[storeId])
          } else {
            console.log("Store with that id doesn't exist")
            setStore(null)
          }
        })
      }
      fetchStore()
    }
  }, [user, userId])

  useEffect(() => {
    //if user is store admin we will fetch his store and if store exists we are fetching products
    if (store?.id) {
      const fetchProducts = async () => {
        const productsRef = ref(db, "products")
        const productsQuery = query(
          productsRef,
          orderByChild("storeId"),
          equalTo(store.id)
        )

        onValue(productsQuery, (snapshot) => {
          if (snapshot.exists()) {
            const productsList = Object.keys(snapshot.val()).map((el) => {
              return snapshot.val()[el]
            })
            setProducts(productsList)
          } else {
            console.log("Products with this id doesn't exist")
            setProducts([])
          }
        })
      }
      fetchProducts()
    }
  }, [store])

  useEffect(() => {
    if (store?.id) {
      const fetchOrders = async () => {
        const orderRef = ref(db, `orders/${store.id}`)

        onValue(orderRef, (snapshot) => {
          if (snapshot.exists()) {
            const ordersData = Object.keys(snapshot.val()).map((key) => {
              return { ...snapshot.val()[key], id: key }
            })
            setOrders(ordersData)
          }
        })
      }
      fetchOrders()
    }
  }, [store])

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        setMakingStore,
        userId,
        setUserId,
        store,
        products,
        orders,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextComponent
