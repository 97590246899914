import "./StoreHeader.css"
import { v4 as uuid } from "uuid"
import modificator from "../../assets/icons/modificator.svg"
import add from "../../assets/icons/add.svg"

const StoreHeader = ({
  store,
  setStoreUpdateFormStatus,
  handleLogout,
  setCustomizeProduct,
}) => {
  return (
    <header className="adminHeader">
      <div>
        <h1>Shop</h1>
        <h2>
          {store?.name}
          <img
            src={modificator}
            alt="modificator button"
            onClick={() => setStoreUpdateFormStatus(true)}
          />
        </h2>
        <p>{store?.description}</p>
      </div>
      <div className="logoutContainer">
        <p className="btn-logout" onClick={handleLogout}>
          Logout
        </p>
        <div className="btn-add">
          <img
            src={add}
            alt="add button"
            onClick={() => setCustomizeProduct(uuid())}
          />
        </div>
      </div>
    </header>
  )
}

export default StoreHeader
