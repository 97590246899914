import SingleItemPreview from "../SingleItemPreview/SingleItemPreview"
import "./StoreItemsContainer.css"

const StoreItemsContainer = ({
  items,
  removeItem = null,
  setCustomizeItem = null,
  type,
}) => {
  return (
    <div className="productsContainer">
      {items.map((item) => {
        return (
          <SingleItemPreview
            item={item}
            key={item.id}
            removeItem={removeItem}
            setCustomizeItem={setCustomizeItem}
            type={type}
          />
        )
      })}
    </div>
  )
}

export default StoreItemsContainer
