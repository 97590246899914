import "./StorageImages.css"

const StorageImages = ({ storageImages, setDeleteImage }) => {
  return (
    <div style={{ padding: "10px 0" }}>
      <h4 style={{ margin: 0 }}>Storage Images</h4>
      <div className="storageImagesContainer">
        {storageImages.map((image, idx) => {
          return (
            <div className="storageImageContainer" key={idx}>
              <img
                className="thumbnail"
                src={image.imageUrl}
                alt="small thumbnail"
              />
              <span
                className="removeBtn"
                onClick={() => setDeleteImage(image.ref)}
              >
                x
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default StorageImages
