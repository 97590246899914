import "./CustomizeProductForm.css"
import cancel from "../../assets/icons/cancel.png"
import modificator from "../../assets/icons/modificator.svg"
import { createRef, useEffect, useState } from "react"
import Select from "react-select"
import { v4 } from "uuid"
import photoImg from "../../assets/icons/photo.svg"
import {
  deleteObject,
  getDownloadURL,
  ref as storageRef,
} from "firebase/storage"
import { ref as dbRef, set } from "firebase/database"
import { db, storage } from "../../firebase/config"
import StorageImages from "../StorageImages/StorageImages"
import { returnValueFromArrays } from "../../helpers/arrayFunc"
import DeleteModal from "../DeleteModal/DeleteModal"

const sizeOptions = [
  { value: "XS", label: "XS" },
  { value: "S", label: "S" },
  { value: "M", label: "M" },
  { value: "L", label: "L" },
  { value: "XL", label: "XL" },
]

const CustomizeProductForm = ({
  customizeProduct,
  closeCustomizeProductForm,
  makeProduct,
}) => {
  const [images, setImages] = useState([{ id: v4(), ref: createRef() }])
  const [storageImages, setStorageImages] = useState([])
  const [deleteImage, setDeleteImage] = useState(null)

  const [name, setName] = useState(customizeProduct.name || "name")

  const [size, setSize] = useState(customizeProduct.size || [""])

  const [price, setPrice] = useState(customizeProduct.price || "1000")

  const [customer, setCustomer] = useState(customizeProduct.customer || "child")

  const [type, setType] = useState(customizeProduct.type || "name")

  const [inStock, setInStock] = useState(customizeProduct.inStock || true)

  const [inputStatuses, setInputStatuses] = useState([
    { name: "name", status: false },
    { name: "size", status: false },
    { name: "price", status: false },
    { name: "customer", status: false },
    { name: "type", status: false },
  ])
  const [error, setError] = useState("")

  const downloadImages = (imageRefs) => {
    try {
      imageRefs.map(async (ref) => {
        const image = storageRef(storage, ref)
        const imageUrl = await getDownloadURL(image)
        setStorageImages((prevState) => [...prevState, { imageUrl, ref }])
        setStorageImages((prevState) => {
          const uniqueSet = new Set(prevState.map(JSON.stringify))
          const uniqueArray = Array.from(uniqueSet, JSON.parse)
          return uniqueArray
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  const submitCustomizeProduct = () => {
    setError(null)
    if (!name || !size[0] || !price || !customer || !type) {
      setError("All fields must be filled")
      return
    }
    setInputStatuses((prevState) => {
      return prevState.map((el) => {
        return { ...el, status: false }
      })
    })
    const sizeData = size.map((el) => {
      return { value: el.value, label: el.label }
    })

    const filledImages = images.filter((img) => {
      if (img.img !== undefined) {
        return img.ref.current.files[0]
      }
    })
    let imgRefs = []
    if (filledImages.length > 0) {
      imgRefs = filledImages.map((img) => {
        //create imgRefs to be used when we make new Doc inside products collection
        const photoFormat = img.ref.current.files[0].name.split(".")[1]

        return storageRef(
          storage,
          `images/products/${
            customizeProduct.id ? customizeProduct.id : customizeProduct
          }/${v4()}.${photoFormat}`
        )
      })
    }
    if (typeof customizeProduct === "string") {
      //make new product
      makeProduct(
        customizeProduct,
        {
          name,
          size: sizeData,
          price,
          customer,
          type,
          inStock,
          id: customizeProduct,
          images:
            imgRefs.length > 0
              ? imgRefs.map((ref) => {
                  return ref.fullPath
                })
              : null,
        },
        imgRefs,
        filledImages
      )
    }
    if (typeof customizeProduct === "object") {
      //update
      makeProduct(
        customizeProduct.id,
        {
          name,
          size: sizeData,
          price,
          customer,
          type,
          inStock,
          id: customizeProduct.id,
          images: returnValueFromArrays(imgRefs, storageImages),
        },
        imgRefs,
        filledImages
      )
    }
    closeCustomizeProductForm()
  }
  const handleCloseInput = (e, index) => {
    if (e.key === "Enter") {
      handleInputStatuses(index)
    }
  }
  const handleInputStatuses = (index) => {
    setInputStatuses((prevState) => {
      return prevState.map((el, idx) => {
        if (idx === index) {
          return { ...el, status: !el.status }
        } else {
          return { ...el, status: false }
        }
      })
    })
  }
  const handleInputUploaded = (id) => {
    //making readable photo after uploading to show instantly

    const file = images.find((el) => el.id === id).ref.current.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const uploadedImage = e.target.result
        setImages((prevState) =>
          prevState.map((el) => {
            if (el.id === id) {
              return { ...el, img: uploadedImage }
            } else {
              return el
            }
          })
        )
      }
      reader.readAsDataURL(file)
    }
    if (images.length + storageImages.length > 4) return
    setImages((prevState) => [...prevState, { id: v4(), ref: createRef() }])
  }

  const handleInputPhotoClicked = (id) => {
    //input is hidden and need to be woked like this
    images.find((el) => el.id === id).ref.current.click()
  }

  const confirmRemovingImage = async () => {
    //if we editing product that allready exists
    try {
      const imageRef = storageRef(storage, deleteImage)
      await deleteObject(imageRef)
      setStorageImages((prevState) =>
        prevState.filter((el) => el.ref !== deleteImage)
      )
      const productRef = dbRef(db, `products/${customizeProduct.id}/images`)
      const updatedImagesProduct = storageImages
        .filter((image) => image.ref !== deleteImage)
        .map((el) => el.ref)
      await set(productRef, updatedImagesProduct)
    } catch (error) {
      console.log(error)
    } finally {
      setDeleteImage(null)
    }
  }

  useEffect(() => {
    if (typeof customizeProduct === "object" && customizeProduct.images) {
      downloadImages(customizeProduct.images)
    }
  }, [customizeProduct])

  return (
    <div className="invisibleWrapper">
      <div className="customizeProductContainer">
        <div className="cancelContainer">
          <div></div>
          <div></div>
          <img
            className="btn-cancel"
            src={cancel}
            alt="cancel button"
            onClick={() => closeCustomizeProductForm()}
          />
        </div>
        {storageImages.length > 0 && (
          <StorageImages
            storageImages={storageImages}
            setDeleteImage={setDeleteImage}
          />
        )}
        {storageImages.length < 5 && (
          <div className="imagesContainer">
            {images.map((input) => (
              <div className="singleInput" key={input.id}>
                <input
                  onChange={() => handleInputUploaded(input.id)}
                  type="file"
                  ref={input.ref}
                />
                <img
                  className="thumbnail"
                  id={input.id}
                  onClick={() => handleInputPhotoClicked(input.id)}
                  src={input.img ? input.img : photoImg}
                  alt="icon"
                />
              </div>
            ))}
          </div>
        )}
        <div className="nameContainer">
          <p className="columnTitle">Name</p>
          {inputStatuses[0].status && (
            <input
              className="customizeInput"
              type="text"
              onChange={(e) => setName(e.target.value)}
              onKeyDown={(e) => handleCloseInput(e, 0)}
              value={name}
            />
          )}
          {!inputStatuses[0].status && (
            <p className="columnValue" onClick={() => handleInputStatuses(0)}>
              {name}
            </p>
          )}
          <img
            className="btn-modificator"
            src={modificator}
            alt="modificator button"
            onClick={() => handleInputStatuses(0)}
          />
        </div>

        <div className="sizeContainer">
          <p className="columnTitle">Size</p>
          {inputStatuses[1].status && (
            <Select
              className="customizeInput"
              isMulti
              options={sizeOptions}
              placeholder="Size"
              value={size}
              onChange={(selected) => setSize([...selected])}
              onKeyDown={(e) => handleCloseInput(e, 1)}
            />
          )}
          {!inputStatuses[1].status && (
            <div className="columnValue" onClick={() => handleInputStatuses(1)}>
              {size.map((el, idx) => {
                return (
                  <p className="size" key={idx}>
                    {el.label}
                  </p>
                )
              })}
            </div>
          )}
          <img
            className="btn-modificator"
            src={modificator}
            alt="modificator button"
            onClick={() => handleInputStatuses(1)}
          />
        </div>

        <div className="priceContainer">
          <p className="columnTitle">Price</p>
          {inputStatuses[2].status && (
            <input
              className="customizeInput"
              type="number"
              onChange={(e) => setPrice(e.target.value)}
              onKeyDown={(e) => handleCloseInput(e, 2)}
              value={price}
            />
          )}
          {!inputStatuses[2].status && (
            <p className="columnValue" onClick={() => handleInputStatuses(2)}>
              {price}$
            </p>
          )}
          <img
            className="btn-modificator"
            src={modificator}
            alt="modificator button"
            onClick={() => handleInputStatuses(2)}
          />
        </div>
        <div className="customerContainer">
          <p className="columnTitle">Customer</p>
          {inputStatuses[3].status && (
            <select
              className="customizeInput"
              value={customer}
              onChange={(e) => setCustomer(e.target.value)}
              onKeyDown={(e) => handleCloseInput(e, 3)}
            >
              <option value="male">male</option>
              <option value="female">female</option>
              <option value="child">child</option>
            </select>
          )}
          {!inputStatuses[3].status && (
            <p
              className="size columnValue"
              onClick={() => handleInputStatuses(3)}
            >
              {customer}
            </p>
          )}
          <img
            className="btn-modificator"
            src={modificator}
            alt="modificator button"
            onClick={() => handleInputStatuses(3)}
          />
        </div>
        <div className="typeContainer">
          <p className="columnTitle">Product type</p>
          {inputStatuses[4].status && (
            <select
              className="customizeInput"
              value={type}
              onChange={(e) => setType(e.target.value)}
              onKeyDown={(e) => handleCloseInput(e, 4)}
            >
              <option disabled>Choose type</option>
              <option value="top">Top</option>
              <option value="bottom">Bottom</option>
              <option value="skirt">Skirt</option>
              <option value="dres">Dres</option>
              <option value="sweater">Sweater</option>
            </select>
          )}
          {!inputStatuses[4].status && (
            <p className="columnValue" onClick={() => handleInputStatuses(4)}>
              {type}
            </p>
          )}
          <img
            className="btn-modificator"
            src={modificator}
            alt="modificator button"
            onClick={() => handleInputStatuses(4)}
          />
        </div>
        <div className="doneContainer">
          <p
            style={
              inStock
                ? { backgroundColor: "#393938", transition: "0.3s all ease" }
                : { backgroundColor: "#39393870", transition: "0.3s all ease" }
            }
            onClick={() => setInStock(!inStock)}
          >
            Out of Stock
          </p>
          <p onClick={submitCustomizeProduct}>Done</p>
        </div>
        <p className="error-msg">{error}</p>
      </div>
      {deleteImage && (
        <DeleteModal
          title="Delete image?"
          cancelRemoving={() => setDeleteImage(null)}
          confirmRemoving={confirmRemovingImage}
        />
      )}
    </div>
  )
}

export default CustomizeProductForm
