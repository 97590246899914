import CsvExportProducts from "../CsvExportProducts/CsvExportProducts"
import "./FilterTag.css"

const FilterTag = ({ filterTag, setFilterTag }) => {
  return (
    <div className="filterTagContainer">
      <p
        className="filterTag"
        onClick={() => setFilterTag("products")}
        style={
          filterTag === "products"
            ? { backgroundColor: "#393938" }
            : { backgroundColor: "#39393870" }
        }
      >
        Products
      </p>
      <p
        className="filterTag"
        onClick={() => setFilterTag("orders")}
        style={
          filterTag === "orders"
            ? { backgroundColor: "#393938" }
            : { backgroundColor: "#39393870" }
        }
      >
        Orders
      </p>
      <CsvExportProducts />
    </div>
  )
}

export default FilterTag
