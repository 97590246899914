import { useContext, useState } from "react"
import "./Login.css"
import { AuthContext } from "../../Context/AuthContext"
import { auth, db } from "../../firebase/config"
import { signInWithEmailAndPassword } from "firebase/auth"
import { ref, child, get } from "firebase/database"
import { setDataToLocalStorage } from "../../helpers/auth"
//toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Login = () => {
  const { setUser, setUserId } = useContext(AuthContext)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  // const [error, setError] = useState(null)

  const handleLogin = async (e) => {
    e.preventDefault()

    // setError(null)
    if (email.trim().length === 0 || password.length === 0) return

    try {
      const result = await signInWithEmailAndPassword(auth, email, password)
      const adminId = result.user.uid
      setUserId(adminId)
      setDataToLocalStorage("adminId", adminId)

      const dbRef = ref(db)
      const admin = await get(child(dbRef, `admins/${adminId}`))
      if (admin.exists()) {
        setUser(admin.val().role)
        setDataToLocalStorage("role", admin.val().role)
        if (admin.val().role === "superadmin") {
          setDataToLocalStorage("credentials", { email, password })
        }
      } else {
        console.log("Admin with that ID doesn't exist")
      }
    } catch (error) {
      console.clear()
      toast.error("Email or password is wrong")
    }
  }

  return (
    <div className="loginWrapper">
      <div className="loginContainer">
        <h1>Login</h1>
        <form className="loginForm" onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
          />
          <button>Done</button>
          {/* {error && (
            <div className="errorContainer">
              <p>{error}</p>
            </div>
          )} */}
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Login
