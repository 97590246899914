import { useState } from "react"
import "./NewStoreForm.css"
//toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const NewStoreForm = ({ disableForm, createNewStore }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const handleCreateForm = (e) => {
    e.preventDefault()

    if (email.trim().length === 0 || password.length === 0) {
      toast.error("Invalid credentials")
      return
    }

    createNewStore(email, password)
  }

  return (
    <div className="invisibleWrapper">
      <div className="newStoreFormWrapper">
        <h1>Open new store</h1>
        <form>
          <input
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <div className="btnWrapper">
            <button onClick={() => disableForm()}>Cancel</button>
            <button onClick={handleCreateForm}>Ok</button>
          </div>
          {/* <p className="error-msg">{error}</p> */}
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default NewStoreForm
