import "./SuperAdmin.css"
import { useContext, useEffect, useState } from "react"
import { v4 as uuid } from "uuid"

import add from "../../assets/icons/add.svg"

//components
import SingleStorePreview from "../../Components/SingleStorePreview/SingleStorePreview"
import NewStoreForm from "../../Components/NewStoreForm/NewStoreForm"
import { AuthContext } from "../../Context/AuthContext"
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
} from "../../helpers/auth"
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth"
import { auth, db } from "../../firebase/config"
import { setNewDoc } from "../../helpers/firebaseWriteData"
import { onValue, ref, remove, update } from "firebase/database"
import DeleteModal from "../../Components/DeleteModal/DeleteModal"
import LoadingAnimation from "../../Components/LoadingAnimation/LoadingAnimation"
//toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const SuperAdmin = () => {
  const { setMakingStore } = useContext(AuthContext)
  const [stores, setStores] = useState([])
  const [formStatus, setFormStatus] = useState(false)
  const [removingStoreId, setRemovingStoreId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  // const [setRemovingAdminId] = useState(null) //sacuvacemo za svaki slucaj
  // const [error, setError] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    const storesRef = ref(db, "stores")
    onValue(storesRef, (snapshot) => {
      if (snapshot.exists()) {
        const storesData = snapshot.val()
        const storesList = Object.keys(storesData).map((key) => ({
          id: key,
          ...storesData[key],
        }))
        setStores(storesList)
        setIsLoading(false)
      } else {
        toast.error("Stores data doesn't exist")
        setStores([])
        setIsLoading(false)
      }
    })
  }, [])

  const switchStoreStatus = async (storeId, newState) => {
    const storeRef = {
      [`stores/${storeId}/enabled`]: !newState,
    }
    try {
      await update(ref(db), storeRef)
    } catch (error) {
      console.log(error)
    }
  }
  const openRemovingModal = (id, adminId) => {
    setRemovingStoreId(id)
    // setRemovingAdminId(adminId)
  }
  const confirmRemovingStore = async () => {
    const storeRef = ref(db, `stores/${removingStoreId}`)
    try {
      await remove(storeRef)
      setRemovingStoreId(null)
    } catch (error) {
      console.log(error)
    }
  }
  const cancelRemovingStore = () => {
    setRemovingStoreId(null)
    // setRemovingAdminId(null)
  }
  const disableForm = () => {
    setFormStatus(false)
  }
  const createNewStore = async (email, password) => {
    setIsLoading(true)
    // setError(null)
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password) //create new admin in auth
      setMakingStore(true)
      const credentials = getDataFromLocalStorage("credentials")
      await signInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      )
      const adminId = result.user.uid

      await setNewDoc("admins", adminId, {
        email,
        id: adminId,
        role: "admin",
      })
      await setNewDoc("users", adminId, {
        data: {
          birthday: "2000-01-01",
          email: email,
          gender: "Male",
          image: "empty",
          name: "Store",
          number: "empty",
          uid: adminId,
        },
      })

      const storeId = uuid()
      await setNewDoc("stores", storeId, {
        name: "Default Name",
        description: "Default description",
        enabled: false,
        adminId: adminId,
        id: storeId,
        stripeId: "",
      })
      setMakingStore(false)
      setFormStatus(false)
      setIsLoading(false)
      toast.success("Store has been created")
    } catch (error) {
      setIsLoading(false)
      if (error.code === "auth/invalid-email") {
        toast.error("Invalid email")
      }
      if (error.code === "auth/email-already-in-use") {
        toast.error("Email already in use")
      }
      if (error.code === "auth/weak-password") {
        toast.error("Password should have at least 6 characters")
      }
      // else {
      //   toast.error(error.code)
      // }
      console.clear()
    }
  }
  const handleLogout = () => {
    removeDataFromLocalStorage("role")
    removeDataFromLocalStorage("adminId")
    removeDataFromLocalStorage("credentials")
    signOut(auth)
  }
  return (
    <div className="superAdminWrapper">
      <div className="superAdminContainer">
        <header className="superAdminHeader">
          <h1>Shops</h1>
          <div className="logoutContainer">
            <p className="btn-logout" onClick={handleLogout}>
              Logout
            </p>
            <div className="btn-add" onClick={() => setFormStatus(true)}>
              <img src={add} alt="add button" />
            </div>
          </div>
        </header>
      </div>
      <div className="storesContainer">
        {stores.map((store) => {
          return (
            <SingleStorePreview
              store={store}
              switchStoreStatus={switchStoreStatus}
              openRemovingModal={openRemovingModal}
              key={store.id}
            />
          )
        })}
      </div>
      {formStatus && (
        <NewStoreForm
          disableForm={disableForm}
          createNewStore={createNewStore}
        />
      )}
      {removingStoreId && (
        <DeleteModal
          title="Delete store?"
          cancelRemoving={cancelRemovingStore}
          confirmRemoving={confirmRemovingStore}
        />
      )}
      <ToastContainer />
      {isLoading && <LoadingAnimation />}
    </div>
  )
}

export default SuperAdmin
