import { createRef, useState } from "react"
import photoImg from "../../assets/icons/photo.svg"
import { v4 } from "uuid"

const StoreUpdateForm = ({ disableForm, updateStoreDetails }) => {
  const [images, setImages] = useState([{ id: v4(), ref: createRef() }])
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault()

    updateStoreDetails(
      name,
      description,
      images[0].img ? images[0].ref.current.files[0] : null
    )
    disableForm()
  }
  const handleInputUploaded = (id) => {
    //making readable photo after uploading to show instantly

    const file = images.find((el) => el.id === id).ref.current.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const uploadedImage = e.target.result
        setImages((prevState) =>
          prevState.map((el) => {
            if (el.id === id) {
              return { ...el, img: uploadedImage }
            } else {
              return el
            }
          })
        )
      }
      reader.readAsDataURL(file)
    }
  }
  const handleInputPhotoClicked = (id) => {
    //input is hidden and need to be woked like this
    images.find((el) => el.id === id).ref.current.click()
  }
  return (
    <div className="invisibleWrapper">
      <div className="newStoreFormWrapper">
        <form onSubmit={handleSubmit}>
          {images.map((input) => (
            <div className="singleInput" key={input.id}>
              <input
                onChange={() => handleInputUploaded(input.id)}
                type="file"
                ref={input.ref}
              />
              <img
                className="thumbnail"
                id={input.id}
                onClick={() => handleInputPhotoClicked(input.id)}
                src={input.img ? input.img : photoImg}
                alt="icon"
              />
            </div>
          ))}
          <input
            type="text"
            placeholder="Store name"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <input
            type="text"
            placeholder="Store description"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
          <div className="btnWrapper">
            <button onClick={() => disableForm()}>Cancel</button>
            <button>Ok</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default StoreUpdateForm
