import "./DeleteModal.css"

const DeleteModal = ({ cancelRemoving, confirmRemoving, title }) => {
  return (
    <div className="invisibleWrapper">
      <div className="modalContainer">
        <h2>{title}</h2>
        <div className="answerContainer">
          <p onClick={() => cancelRemoving()}>Cancel</p>
          <p onClick={() => confirmRemoving()}>OK</p>
        </div>
      </div>
    </div>
  )
}

export default DeleteModal
