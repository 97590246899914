import "./SingleItemPreview.css"
import remove from "../../assets/icons/remove.svg"
import modificator from "../../assets/icons/modificator.svg"

const SingleItemPreview = ({ item, removeItem, setCustomizeItem, type }) => {
  const handleModificator = () => {
    setCustomizeItem(item)
  }
  return (
    <>
      {type === "products" && (
        <div className="singleProductContainer">
          <div className="productProfile">
            <div>
              <div className="productImg"></div>
              <h3 className="productName">{item.name}</h3>
              <div className="btn-modificator">
                <img
                  src={modificator}
                  alt="modificator button"
                  onClick={handleModificator}
                />
              </div>
            </div>
          </div>
          <div className="btn-remove">
            <img
              src={remove}
              alt="remove button"
              onClick={() => removeItem(item)}
            />
          </div>
        </div>
      )}
      {type === "orders" && (
        <div className="singleOrderContainer" onClick={handleModificator}>
          <div className="orderDate">{item.timeStamp}</div>
          <div className="orderSum">{item.totalPrice}$</div>
          <div className="orderStatus">{item.status}</div>
        </div>
      )}
      {type === "order-products" && ( //ovde ti ostaje da odradis renderovanje posebnog ordera!!!
        <div className="singleOrderProductContainer">
          <div>{item.name.toUpperCase()}</div>
          <div>{item.size.toUpperCase()}</div>
          <div>{item.singleProductPrice}$</div>
          <div>{item.quantity}</div>
          <div>{item.singleProductPrice * item.quantity}$</div>
        </div>
      )}
    </>
  )
}

export default SingleItemPreview
