import { useContext, useEffect, useState } from "react"
import "./Admin.css"
import StoreUpdateForm from "../../Components/StoreUpdateForm/StoreUpdateForm"
import { AuthContext } from "../../Context/AuthContext"
import CustomizeProductForm from "../../Components/CustomizeProductForm/CustomizeProductForm"
import { signOut } from "firebase/auth"
import { auth, db, storage } from "../../firebase/config"
import { removeDataFromLocalStorage } from "../../helpers/auth"
import { ref as dbRef, remove, update } from "firebase/database"
import {
  uploadBytes,
  ref as storageRef,
  deleteObject,
  getDownloadURL,
} from "firebase/storage"
import { setNewDoc, updateDoc } from "../../helpers/firebaseWriteData"
import DeleteModal from "../../Components/DeleteModal/DeleteModal"
import TemporarilyClosed from "../../Components/TemporarilyClosed/TemporarilyClosed"
import LoadingAnimation from "../../Components/LoadingAnimation/LoadingAnimation"
import StoreItemsContainer from "../../Components/StoreItemsContainer/StoreItemsContainer"
import StoreHeader from "../../Components/StoreHeader/StoreHeader"
import FilterTag from "../../Components/FilterTag/FilterTag"
import OrderPreview from "../../Components/OrderPreview/OrderPreview"
import StripeElement from "../../Components/StripeElement/StripeElement"
import { useLocation } from "react-router-dom"

const Admin = () => {
  const { store, products, orders } = useContext(AuthContext)
  const [storeUpdateFormStatus, setStoreUpdateFormStatus] = useState(false)
  const [customizeProduct, setCustomizeProduct] = useState(null)
  const [orderPreview, setOrderPreview] = useState(null)
  const [removingProduct, setRemovingProduct] = useState(null)
  const [isOpen, setIsOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [filterTag, setFilterTag] = useState("products")

  const location = useLocation()

  const updateStripeId = async (storeId, stripeId) => {
    try {
      await updateDoc("stores", storeId, { stripeId })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    //when client finish creating stripe account and returns to this page with param ID (stripe id), we make update inside that store
    const params = new URLSearchParams(location.search)
    const stripeId = params.get("id")
    if (stripeId) {
      const localId = JSON.parse(localStorage.getItem("stripeId"))
      if (stripeId === localId) {
        if (store && store.id) {
          updateStripeId(store.id, stripeId)
        }
      } else {
        localStorage.removeItem("stripeId")
      }
    }
  }, [location.search, store])

  useEffect(() => {
    if (store) {
      setIsOpen(store.enabled)
    }
  }, [store])
  const handleLogout = () => {
    removeDataFromLocalStorage("role")
    removeDataFromLocalStorage("adminId")
    signOut(auth)
  }
  const updateStoreDetails = async (name, description, logo) => {
    if (name.trim().length === 0 && description.trim().length === 0 && !logo)
      return

    setIsLoading(true)

    try {
      let logoUrl = ""
      if (logo) {
        if (store.logo) {
          const oldLogoRef = storageRef(
            storage,
            `/images/store/logo/${store.id}/logo`
          )
          await deleteObject(oldLogoRef)
        }
        const logoRef = storageRef(
          storage,
          `/images/store/logo/${store.id}/logo`
        )
        await uploadBytes(logoRef, logo)
        logoUrl = await getDownloadURL(logoRef)
      }

      let storeRef = {
        [`stores/${store.id}/name`]:
          name.trim().length === 0 ? store.name : name,
        [`stores/${store.id}/description`]:
          description.trim().length === 0 ? store.description : description,
      }

      if (logoUrl) {
        storeRef[`stores/${store.id}/logo`] = logoUrl
      }

      await update(dbRef(db), storeRef)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const makeProduct = async (id, newProduct, imgRefs, images) => {
    setIsLoading(true)
    newProduct.storeId = store.id
    newProduct.storeName = store.name

    if (images.length > 0) {
      const imagesPromises = images.map(async (img, idx) => {
        return uploadBytes(imgRefs[idx], img.ref.current.files[0])
          .then(() => {})
          .catch((error) => {
            console.log(error)
          })
      })
      Promise.all(imagesPromises)
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false))
    }
    if (typeof customizeProduct === "string") {
      try {
        await setNewDoc("products", id, newProduct)
        if (images.length === 0) {
          setIsLoading(false)
        }
      } catch (error) {
        console.log(error)
        if (images.length === 0) {
          setIsLoading(false)
        }
      }
    }
    if (typeof customizeProduct === "object") {
      try {
        await updateDoc("products", id, newProduct)
        if (images.length === 0) {
          setIsLoading(false)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const removeProduct = (product) => {
    setRemovingProduct(product)
  }
  const cancelRemovingProduct = () => {
    setRemovingProduct(null)
  }
  const confirmRemovingProduct = async () => {
    setIsLoading(true)
    const productRef = dbRef(db, `products/${removingProduct.id}`)
    try {
      await remove(productRef)

      if (removingProduct.images) {
        removingProduct.images.forEach(async (image) => {
          const imageRef = storageRef(storage, image) //removing images from storage
          await deleteObject(imageRef)
        })
      }
      setRemovingProduct(null)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const closeCustomizeProductForm = () => {
    setCustomizeProduct(null)
  }
  return (
    <div className="AdminWrapper">
      <div className="adminContainer">
        <StoreHeader
          store={store}
          setStoreUpdateFormStatus={setStoreUpdateFormStatus}
          handleLogout={handleLogout}
          setCustomizeProduct={setCustomizeProduct}
        />
        <FilterTag filterTag={filterTag} setFilterTag={setFilterTag} />
      </div>
      {filterTag === "products" && (
        <StoreItemsContainer
          items={products}
          removeItem={removeProduct}
          setCustomizeItem={setCustomizeProduct}
          type="products"
        />
      )}
      {filterTag === "orders" && (
        <StoreItemsContainer
          items={orders}
          setCustomizeItem={setOrderPreview}
          type="orders"
        />
      )}
      {storeUpdateFormStatus && (
        <StoreUpdateForm
          disableForm={() => setStoreUpdateFormStatus(false)}
          updateStoreDetails={updateStoreDetails}
        />
      )}
      {customizeProduct && (
        <CustomizeProductForm
          customizeProduct={customizeProduct}
          closeCustomizeProductForm={closeCustomizeProductForm}
          makeProduct={makeProduct}
        />
      )}
      {orderPreview && (
        <OrderPreview
          orderPreview={orderPreview}
          setOrderPreview={setOrderPreview}
          store={store}
        />
      )}
      {removingProduct && (
        <DeleteModal
          title="Delete product?"
          cancelRemoving={cancelRemovingProduct}
          confirmRemoving={confirmRemovingProduct}
        />
      )}
      {!isOpen && <TemporarilyClosed logout={handleLogout} />}
      {isLoading && <LoadingAnimation />}
      {!store?.stripeId && <StripeElement />}
    </div>
  )
}

export default Admin
