import { ref, set, update } from "firebase/database"
import { db } from "../firebase/config"

export const setNewDoc = async (collection, id, data) => {
  await set(ref(db, `${collection}/` + id), data)
}

export const updateDoc = async (collection, id, data) => {
  const dataRef = makePathOfData(collection, id, data)

  try {
    await update(ref(db), dataRef)
  } catch (error) {
    console.log(error)
  }
}
const makePathOfData = (collection, id, data) => {
  if (typeof data !== "object") {
    console.log("wrong type of data")
    return
  }
  const dataRef = {}

  Object.keys(data).forEach((key) => {
    dataRef[`${collection}/${id}/${key}`] = data[key]
  })
  return dataRef
}
