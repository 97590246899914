import { BrowserRouter, Routes, Route } from "react-router-dom"

import "./App.css"
//pages
import Home from "./Pages/Home/Home"
import Login from "./Pages/Login/Login"
import SuperAdmin from "./Pages/SuperAdmin/SuperAdmin"
import { useContext } from "react"
import { AuthContext } from "./Context/AuthContext"
import Admin from "./Pages/Admin/Admin"

function App() {
  const { user } = useContext(AuthContext)

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              !user ? (
                <Home />
              ) : user === "superadmin" ? (
                <SuperAdmin />
              ) : (
                <Admin />
              )
            }
          />
          <Route
            path="/login"
            element={
              !user ? (
                <Login />
              ) : user === "superadmin" ? (
                <SuperAdmin />
              ) : (
                <Admin />
              )
            }
          />
          <Route
            path="/super-admin"
            element={
              !user ? (
                <Home />
              ) : user === "superadmin" ? (
                <SuperAdmin />
              ) : (
                <Admin />
              )
            }
          />
          <Route
            path="/admin"
            element={
              !user ? (
                <Home />
              ) : user === "superadmin" ? (
                <SuperAdmin />
              ) : (
                <Admin />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
