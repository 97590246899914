import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { useState } from "react"
import "./StripeElement.css"
import {
  STRIPE_BASE_URL,
  STRIPE_CREATE_ACC_API,
} from "../../constants/stripe-url"
import axios from "axios"

const StripeElement = () => {
  //using credit card data makes token, send it to the backend api that creates connected account and link that redirect client to stripe to finish creating account
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(null)
    if (!stripe || !elements) {
      return
    }

    try {
      const result = await stripe.createToken(elements.getElement(CardElement))

      const response = await axios.post(
        STRIPE_BASE_URL + STRIPE_CREATE_ACC_API,
        {
          bankAccToken: result.token.id,
        }
      )
      localStorage.setItem("stripeId", JSON.stringify(response.data.id))
      window.location.href = response.data.url
    } catch (error) {
      setError(error.message)
    }
  }
  const cardElementStyle = {
    base: {
      fontSize: "20px",
      fontFamily: "Arial, sans-serif",
    },
  }
  return (
    <div className="invisibleWrapper">
      <form className="cardForm" onSubmit={handleSubmit}>
        <div>
          <label>
            <h2>Credit Card</h2>
            <CardElement
              options={{ style: cardElementStyle }}
              className="cardElement"
            />
          </label>
        </div>
        {error && <div style={{ color: "red" }}>{error}</div>}
        <button type="submit" disabled={!stripe}>
          Submit
        </button>
      </form>
    </div>
  )
}

export default StripeElement
