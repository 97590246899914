import "./OrderPreview.css"
import cancel from "../../assets/icons/cancel.png"
import StoreItemsContainer from "../StoreItemsContainer/StoreItemsContainer"
import { useEffect, useState } from "react"
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch"
import { update, ref as dbRef } from "firebase/database"
import { db } from "../../firebase/config"

const OrderPreview = ({ orderPreview, setOrderPreview, store }) => {
  const [isDelivered, setIsDelivered] = useState(
    orderPreview.status === "pending" ? false : true
  )

  const changeStatusOfOrder = () => {
    setIsDelivered(!isDelivered)
  }
  useEffect(() => {
    const fetchNewStatus = async () => {
      const orderRef = {
        [`orders/${store.id}/${orderPreview.id}/status`]: isDelivered
          ? "delivered"
          : "pending",
      }

      try {
        await update(dbRef(db), orderRef)
      } catch (error) {
        console.log(error)
      }
    }
    fetchNewStatus()
  }, [isDelivered])
  return (
    <div className="invisibleWrapper">
      <div className="orderPreviewContainer">
        <div className="cancelContainer">
          <div></div>
          <div></div>
          <img
            className="btn-cancel"
            src={cancel}
            alt="cancel button"
            onClick={() => setOrderPreview(null)}
          />
        </div>
        <div className="orderPreviewHeaderContainer">
          <div className="dateContainer">
            <p>Date: </p>
            <p>{orderPreview.timeStamp}</p>
          </div>
          <div className="statusContainer">
            <p>Status: </p>
            <p>{isDelivered ? "delivered" : "pending"}</p>
            <ToggleSwitch
              isDelivered={isDelivered}
              handleToggle={changeStatusOfOrder}
              colorOne="#EF476F"
              colorTwo="#06D6A0"
            />
          </div>
        </div>
        <div className="singleOrderProductContainer">
          <div>
            <b>NAME</b>
          </div>
          <div>
            <b>SIZE</b>
          </div>
          <div>
            <b>PRICE</b>
          </div>
          <div>
            <b>QUANTITY</b>
          </div>
          <div>
            <b>SUM</b>
          </div>
        </div>
        <StoreItemsContainer
          items={orderPreview.orderedProducts}
          type="order-products"
        />
        <hr />
        <div className="sumContainer">
          <b>TOTAL: </b>
          {orderPreview.totalPrice}$
        </div>
      </div>
    </div>
  )
}

export default OrderPreview
