import "./SingleStorePreview.css"
import remove from "../../assets/icons/remove.svg"

const SingleStorePreview = ({
  store,
  switchStoreStatus,
  openRemovingModal,
}) => {
  return (
    <div className="singleStoreContainer">
      <div className="storeProfile">
        <div className="storeImg"></div>
        <h3 className="storeName">{store.name}</h3>
      </div>
      <div
        className="btn-disable"
        onClick={() => switchStoreStatus(store.id, store.enabled)}
        style={
          store.enabled
            ? { backgroundColor: "#62625F", transition: "0.3s all ease" }
            : { backgroundColor: "#393938", transition: "0.3s all ease" }
        }
      >
        <p>{store.enabled ? "disable" : "enable"}</p>
      </div>
      <div className="btn-remove">
        <img
          src={remove}
          alt="remove button"
          onClick={() => openRemovingModal(store.id, store.adminId)}
        />
      </div>
    </div>
  )
}

export default SingleStorePreview
