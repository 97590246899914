import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import AuthContextComponent from "./Context/AuthContext"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
const testPk =
  "pk_test_51NVfi7FMyKQkCfab9eGPl6DznCI49eOMl612kCTDVgiY9o4N3G9TPFmQ2tsHcFXvOujZsOgzPmgyC3bqU4jB8LOL006blCIeju"
const livePk =
  "pk_live_51NVfi7FMyKQkCfabzXODMo3okBSorTMRIqg6T0NTaQ9VXcBeLXQjCpa44XRH0LJh5qgCFdTy8Qiyam2vgrBGtVT700jdGB6F8R"

const stripePromise = loadStripe(livePk)

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <AuthContextComponent>
        <App />
      </AuthContextComponent>
    </Elements>
  </React.StrictMode>
)
